/*
  FILTERS
  Tailwind CSS v.2.1+ contain filter utilities. Our version don't.
  Remove these classes after upgrade.
----------------------------------------------------------------------------- */
.drop-shadow-xl {
  filter: drop-shadow(0px 25px 45px rgba(0, 0, 0, 0.1));
}

.drop-shadow-md {
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.05));
}

/*
  MIN WIDTHS
----------------------------------------------------------------------------- */
.min-w-130 {
  min-width: 130px;
}

@import 'tailwindcss/base';

@font-face {
  font-family: 'Avenir';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/heavy/font.woff2') format('woff2'), url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/heavy/font.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/medium/font.woff2') format('woff2'), url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/book/font.woff2') format('woff2'), url('https://d2ez0ydy7hy5tw.cloudfront.net/avenir/book/font.woff') format('woff');
}

@font-face {
  font-family: 'Financier Display';
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.eot');
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.eot?#iefix') format('embedded-opentype'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.woff2') format('woff2'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/bold/font.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Financier Display';
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/semibold/font.eot');
  src: url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/semibold/font.eot?#iefix') format('embedded-opentype'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/semibold/font.woff2') format('woff2'),
  url('https://d2ez0ydy7hy5tw.cloudfront.net/financier-display/semibold/font.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  @screen lg {
    .vertical-cta-max-w {
      max-width: 240px;
    }
  }

  ul.list-check li {
    position: relative;
    margin-left: 28px;
  }

  ul.list-check li:before {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z' fill='%23308FFB'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    font-weight: bold;
    border-radius: 50%;
    height: 20px;
    left: -10px;
    content: "";
    width: 20px;
  }

  .bg-circle-top-left {
    width: 180px;
  }

  .bg-circle-right {
    margin-top: 272px;
    width: 109px;
  }

  @screen lg {
    .bg-circle-top-left {
      width: 338px;
    }

    .bg-circle-right {
      margin-top: 180px;
      width: 208px;
    }
  }

  .dropdown-item.nuxt-link-active,
  .dropdown-item.nuxt-link-exact-active {
    @apply .bg-blue-100
  }

  .dropdown-item:not(.nuxt-link-active),
  .dropdown-item:not(.nuxt-link-exact-active) {
    @apply .text-indigo-500
  }

  .dropdown-item:hover {
    @apply .text-blue-500
  }
}

@import "nouislider/distribute/nouislider.css";

.noUi-target {
  @apply h-4 bg-blue-100 border-0 rounded-md shadow-none;
}

.noUi-connects {
  @apply rounded-md;
}

.noUi-connect {
  @apply bg-blue-500;
}

.noUi-horizontal .noUi-handle {
  @apply box-border w-8 h-8 bg-gray-100 border-2 border-white border-solid rounded-full shadow cursor-pointer;
  top: -8px;
}

.noUi-horizontal .noUi-handle:focus {
  @apply outline-none;
}

.noUi-handle:before,
.noUi-handle:after {
  @apply bg-blue-500 rounded;
  height: 12px;
  width: 2px;
  top: 8px;
}

.noUi-handle:before {
  left: 11px;
}

.noUi-handle:after {
  left: 15px;
}


body {
  @apply .min-h-screen .bg-gray-100 .text-indigo-500 .antialiased .font-sans .font-normal;
}
