ul li {
  position: relative;
}

ul:not(.list-disc) li:before {
  display: inline-block;
  position: absolute;
  font-weight: bold;
  margin-left: -1em;
  content: "\2022";
  color: #0481F6;
  height: 8px;
  width: 8px;
}
